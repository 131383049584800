import * as React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import _ from "lodash";

import BasicLayout from "../layouts/BasicLayout";
import Section from "../components/ui/section";
import Container from "../components/ui/container";
import PageHeader from "../components/ui/pageHeader";

const BoxContainer = styled.div`
  min-height: 200px;
  padding: 1em;
  min-width: 200px;
`;

const AContainer = styled.a`
  min-height: 250px;
  padding: 1em;
  width: 250px;
`;
class Resources extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: 1,
      resource: {},
      grade: "",
    };
    this.getGradeSubjects = this.getGradeSubjects.bind(this);
    this.getResource = this.getResource.bind(this);
    this.handleGradeSubmit = this.handleGradeSubmit.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
  }
  getGradeSubjects(resources) {
    return [
      ...new Set(
        resources.map((resource) => {
          if (resource.node.frontmatter.grade === this.state.grade) {
            return resource.node.frontmatter.subject;
          }
        })
      ),
    ].sort((a, b) => a.localeCompare(b));
  }
  getResource(resources, subject) {
    return _.find(resources, (resource) => {
      return (
        resource.node.frontmatter.grade === this.state.grade &&
        resource.node.frontmatter.subject === subject
      );
    });
  }
  getSubjectImage(subjects, needed) {
    console.log({ subjects, needed });
    const obj = _.find(subjects, (subject) => {
      return subject.node.frontmatter.title === needed;
    });
    return obj.node.frontmatter.image;
  }
  handleGradeSubmit(grade) {
    this.setState({
      grade: grade,
      stage: 2,
    });
  }
  handleSubjectSubmit(subject, resources) {
    this.setState({
      resource: this.getResource(resources, subject),
      stage: 3,
    });
  }
  handleGoBack() {
    this.setState({
      stage: this.state.stage - 1,
    });
  }
  render() {
    const { data } = this.props;
    const page = {
      title: "Downloadable Resources",
      image: "/images/Banner_1_0.jpg",
    };
    const resources = data.resources.edges;
    const grades = [
      ...new Set(resources.map((resource) => resource.node.frontmatter.grade)),
    ];
    const subjects = data.subjects.edges;
    return (
      <div>
        <BasicLayout pageTitle={page.title}>
          <PageHeader
            title={page.title}
            image={page.image}
            breadcrumb={[{ page: page.title, path: this.props.uri }]}
          />
          <Section>
            <Container padding>
              {/* <h3 className="text-4xl text-center font-bold mb-10 text-gray-700">
                Published Products
              </h3> */}
              <div
                style={{ maxWidth: "1300px" }}
                className="flex gap-4 flex-row flex-wrap justify-center align-center content-center mx-auto"
              >
                {this.state.stage === 1 &&
                  grades.map((grade, i) => (
                    <BoxContainer
                      key={i}
                      className="my-5 flex flex-col bg-blue-100 cursor-pointer shadow-md rounded-lg text-blue-800 text-center hover:shadow-lg hover:text-blue-900"
                    >
                      <div
                        onClick={() => this.handleGradeSubmit(grade)}
                        className="flex flex-col flex-1 justify-center items-center "
                      >
                        <div className="break-words text-3xl">
                          <strong>{grade}</strong>
                        </div>
                      </div>
                    </BoxContainer>
                  ))}
                {this.state.stage === 2 &&
                  this.getGradeSubjects(resources).map(
                    (subject, i) =>
                      subject && (
                        <BoxContainer
                          key={i}
                          className="my-5 flex flex-col bg-blue-100 cursor-pointer shadow-md rounded-lg text-blue-800 text-center hover:shadow-lg hover:text-blue-900"
                        >
                          <div
                            onClick={() =>
                              this.handleSubjectSubmit(subject, resources)
                            }
                            className="flex flex-col flex-1 justify-center items-center "
                          >
                            <img
                              style={{
                                maxWidth: "250px",
                                maxHeight: "250px",
                              }}
                              src={this.getSubjectImage(subjects, subject)}
                              alt={subject}
                            />
                          </div>
                        </BoxContainer>
                      )
                  )}
                {this.state.stage === 3 && (
                  <>
                    {this.state.resource.node.frontmatter.teacher_resources && (
                      <AContainer
                        href={
                          this.state.resource.node.frontmatter.teacher_resources
                        }
                        className="flex flex-col justify-center break-words text-2xl bg-blue-100 shadow-md rounded-lg text-blue-800 text-center hover:shadow-lg hover:text-blue-900"
                        target="blank"
                      >
                        <strong>Teacher's Resources</strong>
                      </AContainer>
                    )}
                    {this.state.resource.node.frontmatter.worksheets && (
                      <AContainer
                        href={this.state.resource.node.frontmatter.worksheets}
                        className="flex flex-col justify-center break-words text-2xl bg-blue-100 shadow-md rounded-lg text-blue-800 text-center hover:shadow-lg hover:text-blue-900"
                        target="blank"
                      >
                        <strong>Worksheets</strong>
                      </AContainer>
                    )}
                    {this.state.resource.node.frontmatter.assessments && (
                      <AContainer
                        href={this.state.resource.node.frontmatter.assessments}
                        className="flex flex-col justify-center break-words text-2xl bg-blue-100 shadow-md rounded-lg text-blue-800 text-center hover:shadow-lg hover:text-blue-900"
                        target="blank"
                      >
                        <strong>Assessments</strong>
                      </AContainer>
                    )}
                  </>
                )}
              </div>
            </Container>
            {this.state.stage > 1 && (
              <div className="flex mt-5 flex-row justify-center">
                <span
                  onClick={() => this.handleGoBack()}
                  className={
                    "text-black mx-auto text-center font-bold py-2 px-4 bg-blue-400 cursor-pointer hover:bg-blue-600 rounded"
                  }
                >
                  Back
                </span>
              </div>
            )}
          </Section>
        </BasicLayout>
      </div>
    );
  }
}

export default Resources;

export const ResourcesQuery = graphql`
  query ResourcesQuery {
    resources: allMdx(
      filter: { frontmatter: { layout: { eq: "resources" } } }
    ) {
      edges {
        node {
          frontmatter {
            title
            subject
            grade
            teacher_resources
            worksheets
            assessments
          }
        }
      }
    }
    subjects: allMdx(filter: { frontmatter: { layout: { eq: "subject" } } }) {
      edges {
        node {
          frontmatter {
            title
            image
          }
        }
      }
    }
    grades: allMdx(filter: { frontmatter: { layout: { eq: "grade" } } }) {
      edges {
        node {
          frontmatter {
            title
            full
          }
        }
      }
    }
  }
`;
